// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-arrangement-js": () => import("./../../../src/templates/arrangement.js" /* webpackChunkName: "component---src-templates-arrangement-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-hall-js": () => import("./../../../src/templates/hall.js" /* webpackChunkName: "component---src-templates-hall-js" */),
  "component---src-templates-halls-js": () => import("./../../../src/templates/halls.js" /* webpackChunkName: "component---src-templates-halls-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-party-js": () => import("./../../../src/templates/party.js" /* webpackChunkName: "component---src-templates-party-js" */),
  "component---src-templates-tickets-js": () => import("./../../../src/templates/tickets.js" /* webpackChunkName: "component---src-templates-tickets-js" */)
}

